<template>
    <div>
        <div class=" _pc-pad-x-280 _pc-pad-top-45 _pc-pad-bottom-65 _mb-pad-x-60" style="background: #f7f7f7;">
			<div class="_pc-mar-top-40 main">
				<div class="main-top _mb-pad-top-115 _mb-pad-bottom-35">
					<div class="main-top-left">
						<div class="title" v-if="lang == 'zh_cn'">{{info.title}}</div>
						<div class="title" v-if="lang == 'ru'">{{info.title_ru}}</div>
						<div class="title" v-if="lang == 'en_us'">{{info.title_us}}</div>
					</div>
				</div>
				<div class="main-body">
					<div v-if="lang == 'zh_cn'" v-html="info.content"></div>
					<div v-if="lang == 'ru'" v-html="info.content_ru"></div>
					<div v-if="lang == 'en_us'" v-html="info.content_us"></div>
				</div>
				<div style="padding-bottom: 30px;display: flex;justify-content: center;">
					<button @click="toRegister(false)" class="disagree-btn">{{$t('我不同意')}}</button>
					<button @click="toRegister(true)" class="consent-btn">{{$t('同意并继续')}}</button>
				</div>
			</div>	
		</div>
    </div>
</template>
<script>
    import { getServiceAgreement } from '@/api/jiyun';
	import { setCookies } from "@/libs/util";
    export default {
        data() {
            return {
                info: {},
                lang: 'ru'
            }
        },
        created(){
            this.lang = localStorage.getItem('think-lang') || 'ru';
            this.getServiceAgreement();
        },
        methods: {
			// 去注册
			toRegister(is_consent){
				if(is_consent){
					setCookies("consent", is_consent);
					this.$router.push({
						name: 'register',
						params: {
							is_consent: is_consent
						}
					})
				}else{
					setCookies("consent", is_consent);
					this.$router.replace({
						path: '/',
					})
				}
			},
            getServiceAgreement(){
                getServiceAgreement().then((res)=>{
                    this.info = res.data;
                }).catch((err)=>{
                    this.$Message.error(err.msg);
                })
            }
        }
    }
</script>
<style scoped>
	.disagree-btn {
		line-height: 30px;
		margin-right: 5px;
		background-color: #FFFFFF;
		color: #999999;
		border: 1px solid #999999;
		border-radius: 5px;
		padding: 0 10px;
	}
	.consent-btn {
		line-height: 30px;
		margin-left: 5px;
		background-color: #2D8CF0;
		color: #FFFFFF;
		border: 1px solid #2D8CF0;
		border-radius: 5px;
		padding: 0 10px;
	}
    .main {
		width: 100%;
		background-color: #fff;
		padding: 0 35px;
	}
    .main-top {
		width: 100%;
		height: 150px;
		border-bottom: 1px solid rgba(153, 153, 153, 0.2);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
    .main-top .main-top-left .title {
		font-size: 32px;
		color: #333333;
		font-weight: bold;
	}
    .main-body {
		padding: 30px 0px;
	}

@media screen and (max-device-width: 768px) {
	.main-top {
		height: unset;
	}
	.main {
		padding: 0 8vw;
	}
	.main-top .main-top-left .title {
		font-size: 6.4vw;
	}
}
</style>